* {
  box-sizing: border-box;
}

.app {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-logo {
  margin-top: 7.5px;
  height: 60px;
  pointer-events: none;
  text-align: center;
}

/* create-react-app logo animation
@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}
*/

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General webpage */
.page {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

/* Regions to the left and right of .content */
.sidebar {
  flex: 1 1 auto;
  text-align: center;
  position: relative;
}

.content {
  background-color: rgba(0, 0, 0, 0.15);
  width: 60%;
  max-width: 1080px;
}

.auth-widget {
  padding-top: 20px;
  font-size: 16px;
}

.auth-widget a {
  color: white;
}

.centered {
  padding-top: 10px;
}

.auth-widget > a, .centered {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 16px;
  color: white;
}

.btn.red:hover {
  /* Produce a hover effect, since .red overrides the default hover effect */
  opacity: 0.8;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.flex {
  flex: 1 1 auto;
}

.flex-display {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-small {
  font-size: 14px;
}

.banner {
  background-image: url("../public/images/banner.jpg");
  background-size: 100%;
  background-position: 50% 50%;
  height: 200px;
}

.banner-container {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 50%, rgba(0, 0, 0, 0) 100%);
  height: 100%;
  width: 100%;
}

.banner-text {
  text-shadow: 2px 2px 4px black;
  margin: 0px;
}

.banner .text-container {
  text-align: right;
}

.banner .author {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
}

.shadowed {
  text-shadow: 2px 2px 4px black;
}

*[data-testid="edit-button"] {
    margin-top: 15px;
}

.card small {
  font-size: 14px;
}

.card-post {
  transition: all 0.2s ease-in-out;
}

.card-post:hover {
  opacity: 0.8;
}

.btn {
  transition: 0.2s all ease-in-out;
}

.post-author {
  position: relative;
}

.post-author > .abs-right {
  top: 0px;
  right: 15px;
}

.post-editor-container .abs-right {
  top: 0px;
  right: 0px;
}

.abs-right {
  position: absolute;
}

.full {
  flex: 1 1 auto;
  width: 100%;
}

.half {
  height: 100vh;
  width: 50%;
}

.DraftEditor-editorContainer {
  height: 100vh;
  overflow: auto;
  font-size: 16px;
}

.post-editor {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4) inset;
}

.scroll {
  overflow-y: scroll;
}

.relative {
  position: relative;
}

.text-justify {
  text-align: justify;
}

.profile {
  margin-top: 20px;
  padding: 0 20px;
}

.profile p {
  font-size: 20px;
}

.profile-avatar {
  border-radius: 50%;
}

.socials {
  text-align: left;
}

.socials * {
  line-height: 1;
  vertical-align: middle;
}

.socials a, .information a {
  font-size: 16px;
}

.social-media-logo {
  margin-right: 12px;
}
